// ======================================================================
// 04-elements - Body
// ======================================================================

body {
  margin: 0;
  padding: 0;
  background-color: #03070B;
  height: 100%;
  overflow-x: hidden;
  color: $white;

  font-kerning: auto;

  &.menu-open {
    overflow: hidden;
    overflow-y: hidden;
    //  height: auto;
  }

  &.home {
    background-color:#000;
    overflow: hidden;

    // footer .fixed {
      
    // }

    // &.menu-open {
    //   // overflow-y: scroll;
    // }
  }

  
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: $jet;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color:$white;    /* color of the scroll thumb */
  border-radius: 0;       /* roundness of the scroll thumb */
  border: 3px solid $jet;  /* creates padding around scroll thumb */
}


