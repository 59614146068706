// ======================================================================
// 04-elements - Custom Fonts
// ======================================================================



// Caslon Doric
// ======================================================================

@include fontface(
  "Caslon Doric Web",
  "CaslonDoricCondensed-Medium-Web",
  500,
  normal,
  woff2 woff
);

// Old Standard
// ======================================================================

@include fontface(
  "Old Standard",
  "oldstandard-regular",
  400,
  normal,
  woff2 woff
);

@include fontface(
  "Old Standard",
  "oldstandard-italic",
  400,
  italic,
  woff2 woff
);

@include fontface(
  "Old Standard",
  "oldstandard-bold",
  700,
  normal,
  woff2 woff
);



// Avenir
// ======================================================================

@include fontface(
  "Avenir Next Regular",
  "AvenirNext-Regular",
  400,
  normal,
  woff2 woff
);


// Angele
// ======================================================================
// @include fontface(
//   "Angele W05",
//   "Angele-Bold",
//   700,
//   normal,
//   woff2 woff otf
// );

// @include fontface(
//   "Angele W05",
//   "Angele-BoldItalic",
//   700,
//   italic,
//   woff2 woff otf
// );

// @include fontface(
//   "Angele W05",
//   "Angele-Italic",
//   400,
//   italic,
//   woff2 woff otf
// );

// @include fontface(
//   "Angele W05",
//   "Angele-Regular",
//   400,
//   normal,
//   woff2 woff otf
// );

// Estilo
// ======================================================================
@include fontface(
  "Estilo",
  "Estilo_Regular",
  400,
  normal,
  woff2 woff otf
);

@include fontface(
  "EstiloText-Light",
  "EstiloText-Light",
  300,
  normal,
  woff2 woff otf
);

// Neutra
// ======================================================================

@include fontface(
  "NeutraDisp",
  "NeutraDisp_Medium",
  500,
  normal,
  woff2 woff otf
);

@include fontface(
  "NeutraDisp-ThinAlt",
  "NeutraDisp_ThinAlt",
  200,
  normal,
  woff2 woff otf
);

@include fontface(
  "NeutraText-Light",
  "NeutraText_Light",
  300,
  normal,
  woff2 woff otf
);

@include fontface(
  "NeutraText-Light",
  "NeutraText_LightItalic",
  300,
  italic,
  woff2 woff otf
);

@include fontface(
  "NeutraTextTF",
  "NeutraTextTF_Book",
  350,
  normal,
  woff2 woff otf
);

@include fontface(
  "NeutraTextTF",
  "NeutraTextTF_LightItalic",
  300,
  italic,
  woff2 woff otf
);


