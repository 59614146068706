// ======================================================================
// 04-elements - Buttons
// ======================================================================

button,
a {
  &:focus,
  &:active {
    outline: none;
  }
}

.btn {
  @include type-free(25px, 0px, 40px, 30px, true);

  @include media("screen", "<=tablet") {
    @include type-free(20px, 0px, 40px, 25px, true);
  }


  font-family: $estilo;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  color: $white;
  text-decoration: none;
  transition: all 0.2s linear;
  svg {
    display: block;
    width: rem(72px);
    height: rem(72px);
    margin: 0 auto;
    margin-bottom: rem(30px);
    transition: all 0.2s linear;
    fill: $white;
  }

  &:hover {
    color: $primary;

    svg {
      fill: $primary;
    }
  }
}
