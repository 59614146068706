// ======================================================================
// 06-components - Side Navigation
// ======================================================================

.side-nav-left,
.side-nav-right {
  position: fixed;
  top: 50%;
}

.side-nav-left {
  z-index: 12;
}

.nav-link,
.info-link,
.info-link-close,
.macrolink,
.thumbslink {
  @extend %fademeup;
  display: block;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }
}

.nav-link {
  width: rem(30px);
  height: rem(18px);

  .side-nav-left &,
  .side-nav-right & {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }

  svg {
    width: 100%;
    height: auto;

    .dark & {
      g {
        transition: 0.3s;
        stroke: $menudarktheme;
      }
    }

    .light & {
      g {
        transition: 0.3s;
        stroke: $menulighttheme;
      }
    }
  }

  .menu-open & {
    svg {
      g {
        transition: 0.3s;
        stroke: $menudarktheme;
      }
    }
  }
}

.info-link,
.info-link-close {
  width: rem(30px);
  height: rem(30px);

  .side-nav-left &,
  .side-nav-right & {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }

  .menu-open & {
    display: none;
  }

  .light & {
    svg {

      circle,
      path {
        transition: 0.3s;
        stroke: $menulighttheme;
      }
    }

    &:hover {
      svg {

        circle,
        path {
          stroke: $menulightthemehover;
        }
      }
    }
  }

  .dark & {
    svg {

      circle,
      path {
        transition: 0.3s;
        stroke: $menudarktheme;
      }

      path+circle {
        fill: $menudarktheme;
      }
    }

    &:hover {
      svg {

        circle,
        path {
          stroke: $menudarkthemehover;
        }

        path+circle {
          fill: $menudarkthemehover;
        }
      }
    }
  }

}

.macrolink,
.thumbslink {
  width: rem(25px);
  height: rem(21px);

  svg {
    .dark & {
      rect {
        transition: 0.3s;
        fill: $menudarktheme;
      }
    }

    .light & {
      rect {
        transition: 0.3s;
        fill: $jet;
      }
    }
  }

  .dark & {
    &:hover {
      svg {
        rect {
          fill: $menudarkthemehover;
        }
      }
    }
  }

  .light & {
    &:hover {
      svg {
        rect {
          fill: $menulightthemehover;
        }
      }
    }
  }
}

