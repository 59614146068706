// ======================================================================
// Hepburn Colors | hello@hashandsalt.com
// ======================================================================

// Basic
$bright: #D2D2D2;
$gray: #646464;
$dark: #454545;
$jet: #000;
$charcoal: #202020;
$white: #ECE2D4;

// Pallette
$primary: #d7ae70;
$secondary: #96def0;
$tertiary: #8e44ad;

// Feedback
$positive: #27ae60;
$negative: #e74c3c;
$warning: #f39c12;
$disabled: #adadad;

// Gradients
$gradient-start: $primary;
$gradient-middle: darken($primary, 20%);
$gradient-end: darken($primary, 40%);

// Menu Color Vars
$menudarktheme: #C6C6C6;
$menudarkthemehover: #4D4D4D;

$menulighttheme: $jet;
$menulightthemehover: #4D4D4D;


