// ======================================================================
// 02-tools - Media
// ======================================================================

%fluidimg {
  width: 100%;
  height: auto;
}

%embed {
  @include ioe;
  margin: 0 0 rem(24px);
}

@keyframes fademein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fademeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

%fademeup {
  animation: fademein 1s;

  .zoom-in-sm &,
  .zoom-out-sm & {
    display: none;
  }
}

%fademedown {
  animation: fademeout 1s;

  .zoom-in-sm &,
  .zoom-out-sm & {
    display: none;
  }
}

