// ======================================================================
// 06-components - Contact
// ======================================================================

.contact-section {
  p {
    font-style: italic;
  }

  .longform .copyright {
    p {
      @include type-free(16.7px, 0px, 8px, 16.7px * 1.2, true);
      font-family: $caslon;
      font-style: normal;
      text-align: center;
      text-transform: uppercase;
      color: #767676;
      letter-spacing: 2px;


      
    }

  }



  .longform {
    text-align: center;

    hr {
      width: rem(180px);
      border-top: 2px solid #d7ae70
    }

    .h2,
    h2 {
      @include type-free(29.2px, 0px, 40px, 35px, true);
      font-family: $caslon;
      color: $primary;
      text-transform: uppercase;
      letter-spacing: 4px;
      text-align: center;
    }

    p {
      font-weight: 350;
      @include type-free(29.2px, 0px, 0px, 35px, true);
      font-style: italic;
      text-align: center;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: rem(60px);
      text-align: center;
    }

    a {
      color: $primary;
      @include type-free(29.2px, 0px, 0px, 35px, true);
      font-weight: 300;
    }

    .general-contact {
      margin-bottom: rem(48px);
      p {
        font-style: normal;
      }
    }
  }
}
