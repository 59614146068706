// ======================================================================
// 06-components - Menu
// ======================================================================

.header-menu {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: rem(90px);

  // .menu-open & {
  //   background-color: $jet;
  // }

  @include maxbreak($bp-medium) {
    grid-template-rows: rem(120px) auto;
    grid-template-columns: 1fr;
  }
}

.menu-right {
  @include minbreak($bp-medium) {
    display: grid;
    align-items: center;
    justify-content: flex-end;
  }
}

.menu,
.menu-toggle {
  display: grid;
  align-items: flex-end;
  width: 100%;
  height: 100vh;
  //justify-content: flex-end;
}

.menu-list {
  padding: 0;
  margin: 0 0 0 0;

  @include media("screen", ">=960px") {
    margin: 0 0 0 rem(48px);
  }
  list-style: none;

  li {
    @include media("screen", "<=960px") {
      margin-bottom: rem(12px);
    }
  }
  @include media("screen", ">=960px") {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: rem(40px);
  }
  @include media("retina2x", "landscape") {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: rem(6px);
  }
  @include media("retina26x", "landscape") {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: rem(6px);
  }

  @include media("retina3x", "landscape") {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: rem(12px);
    row-gap: rem(6px);
  }

  a {
    @include type-free(58px, 0px, 0px, 58px, true);

    font-family: $caslon;
    color: $white;
    text-transform: uppercase;
    // letter-spacing: 8px;
    position: relative;
    padding-left: rem(75px);

    &:before {
      position: absolute;
      width: 35px;
      height: 2px;
      top: 50%;
      left: 0;
      content: "";
      background-color: $primary;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out;
    }

    &:hover::before,
    &.selected::before {
      background-color: $primary;
    }
    @include media("screen", "<=960px") {
      @include type-free(38px, 0px, 0px, 38px, true);
    }
  }
}

.no-scroll {
  overflow-y: hidden;
}

.site-menu {
  background-color: $jet;
  background-image: url("/assets/images/BGMenu.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  padding: rem(150px) rem(24px) rem(150px) rem(43px);

  @include media("screen", "<tablet") {
    padding: rem(54px) rem(12px) 0 rem(12px);
  }

  @include media("screen", "retina3x", "portrait") {
    @include type-free(20px, 0px, 0px, 20px * 1.5, true);
  }

  // @include media("screen", "retina3x", "portrait") {
  //   @include type-free(20px, 0px, 0px, 20px * 1.5, true);
  // }
  @include media("retina2x", "landscape") {
    padding: rem(96px) rem(24px) rem(150px) rem(43px);
  }
  @include media("retina26x", "landscape") {
    padding: rem(96px) rem(24px) rem(150px) rem(43px);
  }

  @include media("retina3x", "landscape") {
    padding: rem(96px) rem(24px) 0 rem(43px);

    .menu-container {
      margin-top: -35px;
    }
  }

  @include media("retina3x", "portrait") {
    padding: rem(96px) rem(12px) rem(96px) rem(12px);
  }

  // @include maxbreak($bp-medium) {
  //   padding: rem(280px) rem(24px) rem(150px) rem(24px);
  // }

  text-align: left;
  display: grid;
  align-items: center;
}

// Active State
.site-menu.site-menu-active {
  opacity: 1;
  visibility: visible;
  position: static;

  ul {
    visibility: visible;
  }
}

.menu-hide {
  .menu-open & {
    display: none;
  }
}

// .top-menu {
//   margin: 0;
//   padding: 0;
//   list-style: none;
//   display: none;
//   .menu-open & {
//     display: block;
//   }

//   li {
//     display: inline-block;

//     margin: 0 rem(22px) 0 0;

//     @include maxbreak($bp-medium) {
//       display: block;
//       width: 100%;
//       text-align: center;
//       margin: 0 0 0 0;
//       padding: rem(24px) 0;
//       border-top: 1px solid rgba(255, 255, 255, 0.1);
//       border-bottom: 1px solid rgba(255, 255, 255, 0.1);
//     }
//   }
//   a {
//     text-transform: uppercase;
//     font-weight: $semibold;
//   }
// }

.header-btn {
  height: 30px;
  width: auto;
  // overflow: hidden;
  // position: absolute;
  // top: 30px;
  // left: 30px;

  z-index: 999;
  padding: 0;
  // padding-right: 60px;
  display: block;
  background: none;
  margin: 0;
  margin-right: rem(43px);
  border: 0;
}

// .header-btn div {
//   line-height: 30px;
// }

// .menu-icon {
//   width: 30px;
//   height: 30px;
//   transform: rotate(0);
//   transition: 0.5s ease-in-out;
//   cursor: pointer;
//   position: absolute;
//   top: 0;
//   left: 0;
// }

// .menu-icon-label {
//   color: #fff;
//   text-transform: uppercase;
//   letter-spacing: 4px;
//   font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
//   font-weight: 600;
// }

// .menu-open .menu-icon-label {
//   display: none;
// }

// .menu-icon span {
//   display: block;
//   position: absolute;
//   height: 4px;
//   width: 100%;
//   background: #fff;

//   opacity: 1;
//   left: 0;
//   transform: rotate(0);
//   transition: 0.25s ease-in-out;
// }

// .menu-icon span:nth-child(1) {
//   top: 0;
//   transform-origin: left center;
// }

// .menu-icon span:nth-child(2) {
//   top: 12px;
//   transform-origin: left center;
// }

// .menu-icon span:nth-child(3) {
//   top: 24px;
//   transform-origin: left center;
// }

// .menu-open .menu-icon span:nth-child(1) {
//   transform: rotate(45deg);
//   top: 2px;
//   left: 4px;
// }

// .menu-open .menu-icon span:nth-child(2) {
//   width: 0%;
//   opacity: 0;
// }

// .menu-open .menu-icon span:nth-child(3) {
//   transform: rotate(-45deg);
//   top: 23px;
//   left: 4px;
// }

// .menuiconopen {
//   display: none;
// }

// .menu-open {
//   .menuiconclosed {
//     display: none;
//   }
//   .menuiconopen {
//     display: block;
//   }
// }

.exploreicon {
  display: inline-block;
  width: 33px;
  height: 34px;
  background-image: url("/assets/images/menuicon.svg");

  .menu-open & {
    background-image: url("/assets/images/menuiconopen.svg");
  }
}

.menu-open {
  #site-header .fixed .nav-link .roll {
    display: none;
  }
}
