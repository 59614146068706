// ======================================================================
// 06-components - Grid List
// ======================================================================

// .macro-list {
//   background-image: url("../../../assets/images/exhibitions/ExhibitionsBK.png");
//   background-repeat: repeat-x;
//   background-size: auto 100%;
//   height: auto;
//   background-attachment: fixed;
// }

.grid-list {
  padding-bottom: rem(48px);
  margin: 0 rem(43px);
  @include media("screen", ">=960px") {
    display: grid;
    grid-column-gap: rem(48px);
    grid-row-gap: rem(96px);
    grid-template-columns: repeat(12, 1fr);
  }
}

.grid-card {
  position: relative;
}

.grid-cell {
  display: grid;
  align-items: center;
  justify-content: center;

  a {
    display: block;
    // transition: 0.3s;

    // img {
    //   transition: transform 0.2s;
    //   transform: scale(0.9);

    //   @include media("screen", ">=960px") {
    //     transform: scale(0.9);
    //   }
    // }
    &:hover {
      color: $primary;

      // img {
      //   transform: scale(1);
      // }
    }
  }
}

.grid-card-img {
  figure {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
    // opacity: 0.5;
    // transition: 0.3s;

    // @include minbreak($bp-medium) {
    //   padding: 5% 10%;
    // }

    a:hover & {
      opacity: 1;
    }
  }
}

.grid-card-detail {
  text-transform: initial;

  text-align: center;

  @include media("screen", "<=960px") {
    padding: rem(24px) 0;
  }

  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  .friendly  {
    @include type-free(33px, 0px, 0px, 33 * 1.2px, true);
    font-family: $caslon;
    text-transform: uppercase;
   
    color: $white;
    letter-spacing: 0.1em;
  }


  margin-top: rem(12px);



  .species {
    @include type-free(20.8px, 0px, 0px, 20.8 * 1.2px, true);
    font-style: italic;
    // text-transform: capitalize;
    font-family: $oldstandard;
    color: $white;
  }
}

.img-holder {
  transform: translateZ(0) scale(1);
  transition: transform 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  position: relative;
  display: block;
}

.img-holder img {
  transform: translateZ(0) scale(1);
  transition: transform 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.img-holder canvas {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  outline: 0;
  background: transparent;
}

.grid-card-img:hover .img-holder {
  transform: translateZ(0) scale(0.97);
}

.grid-card-img:hover .img-holder img {
  transform: translateZ(0) scale(1.1);
}
