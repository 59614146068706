// ======================================================================
// 06-components - Fixed
// ======================================================================

.fixed {
  position: relative;
 
  top: 0;
  left: 0;
  z-index: 20;

  width: 100%;
  height: 100%;


  position: fixed;
  // .menu-open & {
  //   position: fixed;
  // }


}