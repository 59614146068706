// ======================================================================
// 06-components - Site Header
// ======================================================================

#site-header {
  position: relative;

  width: 100%;

  .fixed {
    @include media("screen", "<1707px") {
      background-image: url("../../../assets/images/headergradient.png");

      .home &,
      .video-page &,
      .macro-page & {
        background-image: none;
      }
      background-repeat: repeat-x;

      .menu-open & {
        background-image: none;
      }
    }

    @include media("screen", "<tablet") {
      background-image: url("../../../assets/images/headergradientmobile.png");
    }
  }

  height: rem(96px);

  @include media("screen", "<tablet") {
    height: rem(54px);
  }

  .header-split {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    height: rem(96px);

    @include media("screen", "<tablet") {
      height: rem(54px);
    }

    align-items: center;
    .header-split-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      text-align: center;
      padding-left: rem(38px);

      .ee {
        margin-left: 0;
      }
      @include media("screen", "<tablet") {
        padding-left: rem(12px);
      }

      @include media("retina3x", "portrait") {
        .macro-page & {
          padding-top: 5px;
        }

        @include media(">319px", "<389px") {
          .macro-page & {
            padding-top: 5px;
          }
        }
      }
    }
    .header-split-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      padding-right: rem(38px);
      @include media("screen", "<tablet") {
        padding-right: rem(12px);
      }
    }
  }

  // .fixed .triple-middle {
  //   text-align: center;
  //   .ee {
  //     margin-left: 0;
  //   }
  // }

  .fixed {
    display: grid;
    align-items: center;
    height: rem(96px);

    @include media("screen", "<tablet") {
      height: rem(54px);
    }

    .nav-link {
      .roll {
        @include type-free(29.2px, 0px, 0px, 29.2px, true);

        @include media("screen", "<tablet") {
          @include type-free(24px, 0px, 0px, 24px, true);
        }

        display: inline-block;
      }
      // margin: rem(12px) auto rem(12px) auto;
      // margin-right: rem(43px);
      color: $white;
      height: 30px;

      .menu-open & {
        @include media("screen", "<tablet") {
          display: none;
        }
      }
      font-family: $caslon;

      text-transform: uppercase;
      letter-spacing: 0.1em;

      .exploreicon {
        width: 22px;
        height: 22px;

        @include media("screen", "<tablet") {
          width: 18px;
          height: 18px;
        }
        background-size: contain;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: rem(20px);
      }

      // .menu-open & {
      //   svg {
      //     .cls-3 {
      //       fill: $primary;
      //     }
      //   }
      // }
    }

    .ee {
      // @extend %fademeup;
      display: inline-block;

      color: $white;

      @include type-free(37.5px, 0px, 0px, 37.5px, true);

      @include media("screen", "<tablet") {
        @include type-free(24px, 0px, 0px, 24px, true);
      }

      // @include media("retina3x") {
      //   @include type-free(24px, 0px, 0px, 24px, true);
      // }

      font-family: $caslon;
      letter-spacing: 0.06em;

      margin-left: rem(43px);

      @include media("screen", "<tablet") {
        margin-left: rem(12px);
      }

      .home & {
        display: none;
      }

      .home.menu-open & {
        display: inline-block;
      }
    }
  }

  .logo {
    width: 100%;
    max-width: rem(500px);
  }
}
