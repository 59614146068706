// ======================================================================
// 06-components - Footer
// ======================================================================

footer {
  height: rem(90px);
  width: 100%;
  position: relative;
  text-align: center;
  color: $white;

  @include media("screen", "<1707px") {
    background-image: url("../../../assets/images/footergradient.png");

    @include media("screen", "<tablet") {
      background-image: url("../../../assets/images/footergradientmobile.png");
      background-repeat: repeat-x;
      background-position: bottom;
    }

    background-repeat: repeat-x;

    .macro-page & {
      background-image: none;
    }

    .menu-open & {
      background-image: none;
    }
  }

  // .triple {
  //   grid-template-columns: 450px 1fr 450px;

  // }

  .triple-left {
    justify-content: flex-start;
    padding-left: rem(43px);
    @include media("screen", "<tablet") {
      padding-left: rem(12px);
    }
  }
  .triple-right {
    justify-content: flex-end;
    padding-right: rem(43px);
    @include media("screen", "<tablet") {
      padding-right: rem(12px);
    }
  }

  .fixed {
    height: rem(90px);
    top: auto;
    bottom: 0;
  }

  // font-family: $neutraDispTextTF;
  .triple-middle {


    // @include media("screen", "<1707px") {
    //   div {
    //     display: none
    //   }
    // }


    span {
      @include type-free(41px, 0px, 0px, 50px, true);

      @include media("screen", "<=1270px") {
        @include type-free(22px, 0px, 0px, 22px * 1.2, true);
      }

 
      font-family: $caslon;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: $primary;
      display: inline-block;
      @extend %fademeup;
    }

    p {
      @extend %fademeup;
      @include type-free(25px, 0px, 40px, 30px, true);

      @include media("screen", "<=1270px") {
        @include type-free(18px, 0px, 16px, 25px, true);
      }

      color: $white;
      font-family: $oldstandard;
      font-weight: 300;
      font-style: italic;
      em {
        font-weight: 300;
      }
    }
    .home & {
      font-family: $avenir;
      font-style: normal;

      @include type-free(20px, 0px, 15px, 25px, true);

      @include media("screen", "<=1270px") {
        @include type-free(19px, 0px, 0px, 24px, true);
      }
    }
  }

  // p.copy {
  //   @extend %fademeup;
  //   @include type-free(20.6px, 0px, 0px, 30px, true);
  //   color: #767676;
  //   font-family: $oldstandard;
  //   font-weight: 350;
  //   font-style: normal;
  //   letter-spacing: 2px
  // }

  a {
    @extend %fademeup;
    @include type-free(20.6px, 0px, 0px, 30px, true);

    font-weight: 400;
    font-style: normal;
    &:hover {
      color: $white;
    }
  }
}

.menu-open {
  footer {
    display: none;
  }
}
