// ======================================================================
// 04-elements - Logo
// ======================================================================

#logo {
  text-align: center;
  @extend %fademeup;
  @include type-free(12px, 0px, 0px, 12px, true);
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: rem(1px);
  color: $jet;

  a {
    animation: fadein 2s;
    color: $jet;

    min-width: 100%;
  }

  .dark & {
    color: $bright;

    a {
      color: $bright;
    }
  }
}