// ======================================================================
// 06-components - Page
// ======================================================================

#page {
  display: grid;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  grid-template-rows: rem(96px) 1fr rem(90px);

  @include media("screen", "<tablet") {
    grid-template-rows: rem(54px) 1fr rem(90px);
  
  }




  .info-section &,
   .video-page &, .exhibitions-section & {
    grid-template-rows: rem(96px) 1fr;
    grid-template-areas: "siteheader" "middle";

    @include media("screen", "<tablet") {
      grid-template-rows: rem(54px) 1fr;
      grid-template-areas: "siteheader" "middle";
    }
  }

  grid-template-columns: auto;
  grid-template-areas: "siteheader" "middle" "sitefooter";
}

#site-header {
  grid-area: siteheader;
}

#middle {
  grid-area: middle;
}

#site-footer {
  grid-area: sitefooter;
}

.home {
  #page {
    display: grid;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    grid-template-rows: rem(96px) 1fr;
    @include media("screen", "<tablet") {
      @include media("landscape") {
        grid-template-rows: rem(54px) 1fr;
      }
    }
    @include media("screen", "retina3x") {
 
      grid-template-rows: rem(54px) 1fr;
  
    }

    @include media("screen", "retina26x") {
      grid-template-rows: rem(54px) 1fr;
    }

    grid-template-columns: auto;
    grid-template-areas: "siteheader" "middle";
  }
}
