// ======================================================================
// 04-elements - Media
// ======================================================================

img {
  width: 100%;
  height: auto;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

figure {

  margin: 0 0 0 0;
  padding: 0 0 0 0;

}

// .embed {
//   @include ioe;
//   margin: 0 0 rem(48px);

//   video {
//     &:focus {
//       outline: none;
//     }
//   }
// }

// .video-container {
//   width: 100%;
//   height: auto;
//   margin: 0 auto;
//   line-height: 0;

//   video {
//     object-fit: contain;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//   }

//   &.full-video {
//     height: 100%;
//     max-width: 100%;
//     position: absolute;

//     video {
//       object-fit: cover;
//     }
//   }
// }

#video-container {

  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 80%;
  @include media("screen", "<tablet") {
    max-width: 100%;
  }
  position: relative;
  // display: grid;
  // align-items: center;
  // justify-content: center;

  padding-top: 48px;

  .vidwrap {
    @include ioe;
  }
}