// ==========================================================================
// Captions
// ==========================================================================

$plyr-captions-background: var(--plyr-captions-background, rgba(#000, 0.8)) !default;
$plyr-captions-text-color: var(--plyr-captions-text-color, #fff) !default;

$plyr-font-size-captions-base: $plyr-font-size-base !default;
$plyr-font-size-captions-small: $plyr-font-size-small !default;
$plyr-font-size-captions-medium: $plyr-font-size-large !default;
$plyr-font-size-captions-large: $plyr-font-size-xlarge !default;
