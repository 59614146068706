// ======================================================================
// 06-components - Fast Facts
// ======================================================================

.fast-facts {
  background-image: url("/assets/images/global/rule.png");
  background-repeat: no-repeat;
  // padding: rem(48px) 0;
  text-align: left;


  // dt, dd, .bug-friendly, .bug-sp {
  //   @include type-free(25px, 0px, 0px, 30px, true);
  // }



  dl {
    margin: rem(36px) 0 0 0;
    padding: 0;
  }
  dt {
    color: $primary;
    letter-spacing: 2px;
    text-transform: lowercase;
    font-weight: 700;
    margin: 0 0 0 0;
    @include type-free(25px, 0px, 0px, 30px, true);
    font-variant: small-caps;
  }
  dd {
    @include type-free(25px, 0px, 0px, 33px, true);
    margin: 0 0 rem(24px) 0;
  }
  .bug-friendly {
    color: $primary;
    font-weight: 700;
    @include type-free(25px, 0px, 0px, 33px, true);
  }

  .bug-sp {
    @include type-free(25px, 0px, 0px, 33px, true);
    font-style: italic;
  }
}


