// ======================================================================
// 06-components - Split
// ======================================================================

.info-section {
  background-image: url("../../../assets/images/BG.jpg");
  background-size: 100% 100%;
  height: auto;
  background-attachment: fixed;

  #page-acknowledgements & {
    @include media("screen", "<tablet") {
      .longform {
        .h1,
        h1 {
          // @include type-modular(delta, 0px, 36px);
          @include type-free(45px, 0px, 50px, 45px * 1.2, true);
        }
      }
    }
  }

  // .menu-open & {
  //   height: 100%;
  // }

  #page {
    height: auto;
    min-height: 100%;
  }

  figure {
    margin-bottom: rem(24px);
  }
}
.info-section,
.video-page {
  .info-footer {
    height: auto;
    padding-left: rem(43px);

    @include media("screen", "<tablet") {
      padding-left: 0;
    }
    height: auto;
    p {
      @include type-free(16.7px, 24px, 36px, 16.7px * 1.2, true);
      font-family: $caslon;
      font-style: normal;
      text-align: left;
      text-transform: uppercase;
      color: #767676;
      letter-spacing: 2px;

      @include media("screen", "<tablet") {
        text-align: center;
      }
    }
  }
}

.macro-info-section {
  #site-footer {
    position: fixed;
    bottom: 0;
  }
}

.info {
  margin: 0 auto;
  width: 100%;
  max-width: rem(700px);
  padding: 0 rem(48px);

  p {
    @include type-free(14px, 24px, 24px, 14px * 1.2, true);
    max-width: 80%;
  }

  img {
    width: 100%;
    height: auto;
  }

  figure {
    width: 100%;
    margin-bottom: rem(44px);
  }

  .image-left {
    @include minbreak($bp-medium) {
      float: left;
      margin-right: rem(44px);
      width: 50%;
    }
  }

  .image-right {
    @include minbreak($bp-medium) {
      float: right;
      margin-left: rem(44px);
      width: 50%;
    }
  }
}

.background-image {
  background-size: cover;
  background-attachment: fixed;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.info-split {
  display: grid;

  @include media("screen", ">=960px") {
    grid-template-columns: 2fr 1fr;
    column-gap: 48px;
  }
}

.info-right {
  padding-top: rem(96px);

  h2,
  .h2 {
    color: $white;
  }
}
