// ======================================================================
// 06-components - Pinch Pull
// ======================================================================


.pinchpull {
  transition: opacity 0.8s ease-in-out;
  opacity: 1;
  display: none;
  pointer-events: none;
  .touch-false & {
    display: none;
  }

  .touch-true & {
    
    display: grid;
  }

  //  display: none;

  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
  }

  -webkit-user-select: none;
  user-select: none;

  * {
    -webkit-user-select: none;
    user-select: none;
  }

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 500000;
  background-color: rgba($jet, 0.8);

  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
    font-weight: 900;

    text-shadow: 0 0 5px #000,2px 2px 3px #000,0 0 20px #000;
    @include type-free(30px, 0px, 0px, 40px, true);
    display: inline-block;  
   
  }
}
