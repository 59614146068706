// ======================================================================
// 06-components - Exhibitions
// ======================================================================

#ex {
  height: auto;
}

.ex-intro {
  margin-bottom: 48px;
  overflow: hidden;
}

.exhibitions-section {
  background-image: url("../../../assets/images/BG.jpg");
  background-size: 100% 100%;
  height: auto;
  background-attachment: fixed;

  .full-middle,
  .triple-middle {
    align-items: flex-start;
  }

  .intro {
    margin-bottom: rem(135px);
  }

  .exhibit {
    text-align: left;
    padding: 24px 0 0 0;
    // overflow: hidden;
    position: relative;
    &:before {
      content: "";
      width: 505px;
      @include media("screen", "<tablet") {
        width: 100%
      }
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .tns-outer {
    position: relative;
  }

  .tns-outer [aria-controls],
  .tns-outer [data-action] {
    position: relative;
    left: auto;
  }

  .tns-controls {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;

    button {
      border: 0;
      padding: 0;
      margin: 0;
      display: block;
      width: 50%;
      height: 100%;
      float: left;
      text-indent: -9999px;
      background-color: transparent;

      cursor: url("/assets/images/global/left.svg"), auto;
    }

    button + button {
      cursor: url("/assets/images/global/right.svg"), auto;
    }
  }

  .counter {
    overflow: hidden;
    padding: rem(14px) 0 0 0;

    @include type-free(16px, 0px, 24px, 16 * 1.5px, true);
    font-weight: 900;
    font-family: $neutraDispTextTF;
  }
}

.longform {
  max-width: rem(1010px);
  margin: 0 auto;
  text-align: center;
  padding-top: rem(96px);
  padding-bottom: rem(96px);
  h1,
  .h1 {
    text-align: center;
    display: inline-block;
  }

  .h2,
  h2 {
    @include type-free(41.7px, 40px, 40px, 41.75px * 1.2, true);
    font-family: $caslon;
    color: $primary;
    text-transform: uppercase;
    letter-spacing:0.1em;
    text-align: left;
    // letter-spacing: 1px;
    // font-weight: 600;
    // text-shadow: 0 0 5px #000, 2px 2px 3px #000, 0 0 20px #000;
  }

  p {
    @include type-free(25px, 0px, 24px, 33.3px, true);
    font-weight: 400;
    text-align: left;
  }

  em {
    @include type-free(25px, 0px, 0px, 33.3px, true);
    display: inline-block;
    font-style: italic;
    // color: #767676;
    text-align: left;
  }

  .text {
    margin-top: rem(38px);
  }

  .top-text {
    margin-bottom: rem(85px);
  }
}
