// ======================================================================
// 06-components - Triple
// ======================================================================

.full,
.triple {
  display: grid;
  grid-template-columns: rem($gutter) 1fr rem($gutter);
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
  position: relative;
}

.triple {
  @include media("screen", "<=tablet") {
    grid-template-columns: 50px 1fr 50px;
  }
}

.full {
  grid-template-columns: rem(43px) 1fr rem(43px);

  @include media("screen", "<tablet") {
    grid-template-columns: rem(12px) 1fr rem(12px);
  }

  // @include media("screen", "<=1624px") {
  //   grid-template-columns: 2rem 1fr 2rem;
  // }

  // @include media("screen", ">1624px") {
  //   grid-template-columns: rem($gutter) 1fr rem($gutter);
  // }
}

.triple-left,
.triple-middle,
.triple-right {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.full-middle,
.triple-middle {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
}

.full-middle {
  grid-column-start: 2;
  position: relative;
}

.exhibitions-section,
.macro-list,
.info-section {
  &.menu-open {
    .full,
    .grid-list {
      display: none;
    }
  }
}

.macro-page {
  &.menu-open {
    #site-footer .fixed {
      display: none;
    }
  }
}
