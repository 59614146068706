// ======================================================================
// 06-components - Macro
// ======================================================================

.macro-list {
  background-image: url("../../../assets/images/MacroBG.jpg");
  background-repeat: repeat-x;
  background-size: auto cover;
  background-attachment: fixed;
}

.macro-page {
  overflow: hidden;
  background-image: url("../../../assets/images/MacroBG.jpg");
  object-fit: cover;
  background-size: 100% 100%;

  // &.menu-open {
  //   overflow-y: scroll;
  // }

  #middle {
    .triple,
    .triple-middle {
      position: static;
    }
  }
}

#macros {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: rem(16px);
  color: $bright;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.macro-nav {
  position: fixed;
  width: rem(44px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10000;

  @include maxbreak($bp-medium) {
    display: none;
  }
}

.arrow-butt {
  height: 33px;
  width: 22px;
  display: block;
  margin: 0 0 0 0;

  @include media("screen", ">=tablet") {
    margin: 0 0 rem(9px) rem(22px);
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.grid-butt {
  width: 40px;
  height: 34px;
  display: block;
  img {
    width: 100%;
    height: 100%;
  }
}

.info-butt {
  position: relative;
  margin-left: 17px;

  .macro-info-section & {
    margin-left: 0;
  }
  .menu-open & {
    display: none;
  }
  .roll {
    @include type-free(24px, 0px, 0px, 24px, true);
    display: inline-block;
    margin-left: 29px;


    @include media("retina3x", "portrait") {
    

      @include media(">319px", "<389px") {
        margin-left: 15px;
      }
    }

    .macro-info-section & {
      margin-left: 0;
    }

    footer &,
    #site-header & {
      color: $white;
    }
  }
  // margin: rem(12px) auto rem(12px) auto;
  // margin-right: rem(43px);
  footer &,
  #site-header & {
    span.roll {
      height: 30px;


      @include type-free(29.2px, 0px, 0px, 29.2px, true);

      @include media("screen", "<tablet") {
        @include type-free(24px, 4px, 0px, 24px, true);
      }

      font-family: $caslon;

      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }
  .infoicon {
    width: 25px;
    height: 25px;
    @include media("screen", "<tablet") {
      width: 21px;
      height: 21px;
    }
    background-size: contain;
    position: absolute;
    top: 22%;
    left: -17px;

    transform: translateY(-25%);

    .macro-info-section & {
      display: none;
    }

    #site-header & {
      top: 43%;
      transform: translateY(-50%);
    }

    // margin-left: rem(10px);
    background-image: url("/assets/images/global/info.svg");
    background-repeat: no-repeat;
    display: inline-block;
  }

  .menu-open {
    #site-header .fixed .nav-link .roll {
      display: none;
    }
  }

  // .menu-open & {
  //   svg {
  //     .cls-3 {
  //       fill: $primary;
  //     }
  //   }
  // }
}

.gridicon {
  width: 26px;
  height: 20px;
  display: block;

  @include media("screen", "<=tablet") {
    display: none;
  }

  #site-header & {
    display: block;
    margin: -3px 22px 0 0;


    @include media("retina3x", "portrait") {
    

      @include media(">319px", "<389px") {
        margin: -6px 10px 0 0;
      }
    }

    @include media("screen", ">=tablet") {
      display: none;
    }

    .menu-open & {
      display: none;
    }
  }
  margin: -10px 22px 0 0;
  svg {
    width: 100%;
    height: 100%;
  }

  svg g {
    transition: fill 0.4s ease;
    fill: $white;
  }

  &:hover {
    svg g {
      fill: $primary;
    }
  }
}

// .info-butt {
//   height: 29px;
//   width: auto;
//   display: block;
//   font-family: $caslon !important;
//   text-transform: uppercase;
//   letter-spacing: 2px;
//   color: $white !important;
//   @include type-free(29px, 0px, 0px, 40px, true);
//   line-height: 29px;

//   &:hover {
//     color: $primary !important;
//   }

//   svg {
//     width: 25px;
//     height: 25px;
//     margin-left: rem(15px);
//     float: right;
//     display: block;
//   }
// }

// .arrow-butt,
// .grid-butt,
// .info-butt {
//   opacity: 1;

//   &:hover {
//     transition: opacity 1s ease-in-out;

//     opacity: 0.5;
//   }
// }

#info {
  display: none;
  .tobii & {
    display: inline;
  }

  p {
    max-width: 70%;
  }
}

@keyframes titlefademein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes titlefademeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

%titlefademeup {
  animation: titlefademein 1s;
}

%titlefademedown {
  animation: titlefademeout 1s;
}

.macro-page.fullzoom {
  .title,
  .ee {
    opacity: 1;
  }
}

.macro-page.zoomed {
  .title,
  .ee {
    opacity: 0;
  }
}

.macro-page.zoomed {
  .title,
  .ee {
    @extend %titlefademedown;
  }
}

.macro-page.fullzoom {
  .title,
  .ee {
    @extend %titlefademeup;
  }
}

.macro-page,
.macro-info-section {
  #site-header {
    @include media("screen", ">=tablet") {
      .info-butt {
        display: none;
      }
    }
    @include media("screen", "<=tablet") {
      .ee {
        display: none;
      }

      // .triple-left {
      //   padding-left: rem(43px);
      // }
    }
  }
}

.macro-page.menu-open,
.macro-info-section.menu-open {
  #site-header {
    .ee {
      display: block;
    }
  }
}

footer {
  .triple {
    grid-template-columns: rem($gutter) 1fr rem($gutter);
  }

  .triple {
    @include media("screen", "<=tablet") {
      grid-template-columns: 44px 1fr 44px;
    }
  }

  .arrow-butt-left {
    display: none;
  }

  @include media("screen", "<=tablet") {
    .arrow-butt-left {
      display: block;
    }
  }

  .arrow-butt-right {
    display: block;
  }

  @include media("screen", "<=tablet") {
    .info-butt,
    .arrow-butt-right {
      display: none;
    }
  }
}
