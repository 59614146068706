// ======================================================================
// 06-components - Grid
// ======================================================================


.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1rem;



}

.grid-collections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: rem(24px);



    figure {
        width: 100%;
        height: 100%;
        line-height: 0;
    }

    a {
        width: 100%;
        height: 100%;
        line-height: 0;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        transform: none;
    }
}

.grid__item {
    padding: 1rem;
}