// ======================================================================
// 06-components - Transitons
// ======================================================================

.transition-fade {
  transition: 0.5s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}

.roll,
.rollinvert {
  text-decoration: none;
  // font-weight: 900;
}

.roll span,
.rollinvert span {
  transition: color 0.035s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: calc(0.035s * var(--ch-id));
}

.roll:hover,
.button-bar-item:hover .button-bar-link .roll,
.grid-card:hover .roll {
  color: #d7ae70 !important;
}

.rollinvert:hover,
.button-bar-item:hover .button-bar-link .rollinvert,
.grid-card:hover .rollinvert {
  color: $white !important;
}

.fademeup {
  transform: translateY(50px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.info-section {
  .longform p,
  .longform h1,
  .longform h2,
  .longform ul,
  .longform hr,
  .longform figure {
    transform: translateY(12px);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 1s;
    transition-timing-function: linear;
  }
  .longform ul.text-in-view,
  .longform p.text-in-view,
  .longform h1.text-in-view,
  .longform h2.text-in-view,
  .longform hr.text-in-view,
  .longform figure.text-in-view {
    transform: none;
    opacity: 1;
  }
}

.fast-facts {
  transform: translateY(12px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.fast-facts.text-in-view {
  transform: none;
  opacity: 1;
}

.exhibitions-section {
  .ex-fade {
    transform: translateY(12px);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 1s;
    transition-timing-function: linear;
  }
  .ex-fade.ex-in-view {
    transform: none;
    opacity: 1;
  }
}

.thumb-in-view {
  transform: none;
  opacity: 1;
}

