// ======================================================================
// 06-components - Splash
// ======================================================================

.splash {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: rem(160px) 1fr rem(100px) rem(117px);
  row-gap: rem(24px);
  column-gap: rem(24px);
  height: 100%;
  width: 100%;
  grid-template-areas:
    "splashtop splashtop"
    "splashimg splashimg"
    "byline byline"
    "btnleft btnright";

  @include media(">319px", "<389px") {
    @include media("portrait") {
      grid-template-rows: rem(90px) 1fr rem(56px) rem(80px);
    }
  }

  @include media("landscape") {
    @include media("<tablet") {
      grid-template-columns: 190px 1fr 190px;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "splashtop splashtop splashtop"
        "btnleft splashimg btnright"
        "btnleft splashimg btnright";
      row-gap: rem(12px);
      column-gap: rem(12px);

      .splash-top {
        .h1,
        h1 {
          @include type-free(28px, 0px, 8px, 28px x 1.2, false);
        }
        .h2,
        h2 {
          @include type-free(16px, 0px, 0px, 16px, true);
        }
      }
    }

    .byline {
      @include media("<tablet") {
        display: none;
      }

      @include media("landscape") {
        @include media("screen", "retina3x") {
          display: none;
        }
      }
    }
  }

  @include media(">tablet") {
    grid-template-rows: rem(180px) 1fr rem(150px);
    grid-template-columns: 190px 1fr 1fr 1fr 1fr 190px;
    grid-template-areas:
      "splashtop splashtop splashtop splashtop splashtop splashtop"
      "btnleft splashimg splashimg splashimg splashimg btnright"
      "byline byline byline byline byline byline";

    @include media("landscape") {
      @include media("screen", "retina3x") {
        grid-template-rows: rem(84px) 1fr rem(150px);
        grid-template-columns: 190px 1fr 1fr 1fr 1fr 190px;
      }
    }
  }

  @include media("screen", "retina3x") {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: rem(100px) 1fr rem(50px) rem(85px);
    row-gap: rem(12px);
    column-gap: rem(12px);

    @include media("landscape") {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: rem(100px) 1fr rem(85px);
      row-gap: rem(12px);
      column-gap: rem(12px);
    }
  }

  @include media("screen", "retina2x") {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: rem(100px) 1fr rem(60px) rem(120px);
    row-gap: rem(12px);
    column-gap: rem(12px);

    @include media("landscape") {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      row-gap: rem(12px);
      column-gap: rem(12px);

      grid-template-areas:
        "splashtop splashtop splashtop"
        "btnleft splashimg  btnright";
    }
  }

  @include media("screen", "retina26x") {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: rem(100px) 1fr rem(60px) rem(120px);
    row-gap: rem(12px);
    column-gap: rem(12px);

    @include media("landscape") {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      row-gap: rem(12px);
      column-gap: rem(12px);

      grid-template-areas:
        "splashtop splashtop splashtop"
        "btnleft splashimg  btnright";
    }
  }

  @include media("screen", "retina4x") {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: rem(100px) 1fr rem(60px) rem(120px);
    row-gap: rem(12px);
    column-gap: rem(12px);

    @include media("landscape") {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      row-gap: rem(12px);
      column-gap: rem(12px);

      grid-template-areas:
        "splashtop splashtop splashtop"
        "btnleft splashimg  btnright";
    }
  }
}

.splash-top {
  grid-area: splashtop;

  .h1,
  h1,
  .h2,
  h2 {
    text-align: center;
  }

  .h1,
  h1 {
    @include type-free(87.1px, 0px, 20px, 104.5px, false);

    @include media("<phone") {
      @include type-free(30px, 0px, 8px, 30px * 1.2, false);
    }

    @include media("<690px") {
      @include type-free(60px, 0px, 20px, 60px * 1.2, false);
    }

    @include media("screen", "retina2x") {
      @include type-free(48px, 0px, 8px, 48px * 1.2, false);
    }

    @include media("screen", "retina26x") {
      @include type-free(48px, 0px, 8px, 48px * 1.2, false);
    }

    @include media("screen", "retina3x") {
      @include type-free(48px, 0px, 8px, 48px * 1.2, false);

      @include media(">319px", "<389px") {
        @include media("portrait") {
          @include type-free(38px, 0px, 8px, 38px * 1.2, false);
        }
      }
    }

    font-family: $caslon;
    color: $white;
    text-transform: capitalize;
    letter-spacing: 0.06em;
  }

  .h2,
  h2 {
    @include type-free(25.4px, 0px, 0px, 30.5px, true);

    @include media("<phone") {
      @include type-free(20px, 0px, 0px, 30px, true);
    }


    @include media("screen", "retina2x") {
      @include type-free(20px, 0px, 0px, 30px, true);

      @include media(">319px", "<389px") {
        @include media("portrait") {
          @include type-free(18px, 0px, 8px, 18px * 1.2, false);
        }
      }
    }

    @include media("screen", "retina3x") {
      @include type-free(20px, 0px, 0px, 30px, true);

      @include media(">319px", "<389px") {
        @include media("portrait") {
          @include type-free(18px, 0px, 8px, 18px * 1.2, false);
        }
      }
    }


    @include media("screen", "retina26x") {
      @include type-free(20px, 0px, 0px, 30px, true);
    }

    font-family: $avenir;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
}

.btnleft {
  grid-area: btnleft;
  display: grid;
  align-items: center;
  justify-content: center;
}

.splashimg {
  grid-area: splashimg;
  position: relative;
  figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.btnright {
  grid-area: btnright;
  display: grid;
  align-items: center;
  justify-content: center;
}

.byline {
  grid-area: byline;
  display: grid;
  align-items: center;
  justify-content: center;

  p {
    font-family: $avenir;
    font-style: normal;
    font-weight: 400;
    color: $white;
    text-align: center;
    letter-spacing: 0.2em;
    @include type-free(20.8px, 0px, 15px, 20.8px * 1.2, true);

    @include media("retina3x", "portrait") {
      @include type-free(16px, 0px, 15px, 21px, true);
    }

    @include media("retina3x", "portrait") {
      @include type-free(12px, 0px, 0px, 12px * 1.5, true);

      @include media(">319px", "<389px") {
        @include type-free(10px, 0px, 0px, 10px * 1.5, true);
      }
    }

    @include media("screen", "retina2x") {
      @include type-free(12px, 0px, 0px, 12px * 1.5, true);

      @include media("landscape") {
        display: none;
      }
    }
    @include media("screen", "retina26x") {
      @include type-free(12px, 0px, 0px, 12px * 1.5, true);

      @include media("landscape") {
        display: none;
      }
    }

    @include media("screen", "retina4x") {
      @include type-free(12px, 0px, 0px, 12px * 1.5, true);
      @include media("landscape") {
        display: none;
      }
    }
  }
}

.button-bar-item {
  &:hover {
    .butt-link {
      @include media(">tablet") {
        visibility: visible;
      }
    }
  }
}

.butt-link {
  @include type-free(29.2px, 0px, 0px, 35px, true);

  @include media(">tablet") {
    visibility: hidden;
  }

  @include media("screen", "retina3x", "portrait") {
    @include type-free(20px, 0px, 0px, 20px * 1.5, true);
  }
  @include media("screen", "retina2x") {
    @include type-free(20px, 0px, 0px, 20px * 1.5, true);
  }
  @include media("screen", "retina26x") {
    @include type-free(20px, 0px, 0px, 20px * 1.5, true);
  }

  // @include media(">319px", "<=tablet") {
  //   @include type-free(16px, 0px, 0px, 25px, true);
  // }

  font-family: $caslon;
  text-transform: uppercase;
  color: $white;
  text-decoration: none;
  transition: all 0.2s linear;
  display: inline-block;
  letter-spacing: 0.1em;
}

.buttvideo,
.buttgallery {
  svg {
    path {
      fill: #404a51;
    }
  }
}

.buttvideo:hover,
.buttgallery:hover {
  svg {
    path {
      transition: fill 0.5s ease;
      fill: $primary;
    }
  }
}

.button-bar-item {
  display: grid;
  grid-template-areas: "butt" "link";
  align-items: center;
  justify-content: center;
  grid-template-rows: 58px 35px;
  grid-template-columns: 1fr;
  row-gap: 8px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  @include media("screen", ">tablet") {
    grid-template-areas: "link" "butt";
    grid-template-rows: 35px 58px;
  }

  @include media("retina3x", "portrait") {
    grid-template-rows: 38px 35px;
    row-gap: 4px;
  }
}

.button-bar-icon {
  grid-area: butt;
  text-align: center;
  width: 100%;
}

.button-bar-link {
  grid-area: link;
  width: 100%;
}

.button-bar-icon a {
  display: inline-block;
  width: 58px;
  height: 58px;

  @include media("screen", "retina3x", "portrait") {
    width: 38px;
    height: 38px;
  }

  @include media("<phone") {
    width: 38px;
    height: 38px;
  }
}

.button-bar-inner-left,
.button-bar-inner-right {
  display: grid;
  justify-content: flex-start;
  padding: 0 0 0 0;
  @include media("screen", "<tablet") {
    justify-content: center;
  }
}
