// ======================================================================
// 04-elements - Typography
// ======================================================================

// ======================================================================
// Paragraphs
// ======================================================================

%intro-para {
  font-family: $neutraDispTextTF;
  // @include type-free(22px, 0px, 48px, 22px * 1.4, true);
  @include type-free(20px, 0px, 48px, 20px * 1.4, true);
  font-weight: 500;

  strong {
    font-weight: 600;
  }
}

%body-para {
  // @include type-free(22px, 0px, 26px, 22px * 1.2, true);
  @include type-free(24px, 0px, 26px, 24px * 1.5, true);

  strong {
    font-weight: 700;
  }

  em {
    font-weight: 700;
  }
}
// ======================================================================
// Root
// ======================================================================

// :root {
//   @include responsive-type;
// }

// ======================================================================
// HTML
// ======================================================================

html {
  @include rem-baseline(100%);
  line-height: $line-height;
  font-family: $font-stack;
  font-weight: $font-weight;
  text-rendering: optimizeLegibility;
  @include font-smoothing(on);
}

body {
  @include baseline;
  color: $font-color;

  &.light * {
    color: $jet;
  }
}
// ======================================================================
// Headings
// ======================================================================

.site-title,
.slide-count {
  @extend %fademeup;
  @include type-free(12px, 18px, 18px, 12px, true);
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: rem(1px);
  color: $jet;

  a {
    animation: fadein 2s;
    color: $jet;
    display: block;
    min-width: 100%;
  }

  .dark & {
    color: $bright;

    a {
      color: $bright;
    }
  }

  // #collection-section & {
  //   color: $bright;
  //
  //   a {
  //     color: $bright;
  //   }
  // }
}

.h1,
h1 {
  // @include type-modular(delta, 0px, 36px);
  @include type-free(58px, 0px, 50px, 70px, true);



}

.h2,
h2 {
  // @include type-modular(delta, 0px, 12px);
  @include type-free(32px, 0px, 0px, 54px, true);
}

.h3,
h3 {
  // @include type-modular(epsilon, 0px, 0px );
  @include type-free($base-font-size, 0px, 36px, 28px * 1.2, true);
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  // @include type-modular(epsilon, 0px, 0px );
  @include type-free($base-font-size, 0px, 36px, 28px * 1.2, true);
}

.h1,
.h2,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  font-family: $heading-stack;
  font-weight: $heading-weight;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-kerning: auto;
}
// ======================================================================
// Paragraphs
// ======================================================================

p {
  @extend %body-para;
}
// ======================================================================
// Lists
// ======================================================================

ol,
ul {
  // @include type-modular(zeta);
  @include type-free($base-font-size, 0px, 36px, $base-font-size * 1.2, true);

  ol {
    margin-bottom: 0;
    margin-top: 0;
  }

  ul {
    margin-bottom: 0;
    margin-top: 0;
  }
}

dl {
  // @include type-modular(zeta);
  @include type-free($base-font-size, 0px, 36px, $base-font-size * 1.2, true);

  dt {
    font-weight: $semibold;
  }

  dd {
    font-style: italic;
  }
}
// ======================================================================
// Tables
// ======================================================================
// table {
//   @include table($default-table);
// }
// ======================================================================
// Quotes
// ======================================================================

blockquote {
  // @include type-modular(zeta);
  @include type-free($base-font-size, 0px, 36px, $base-font-size * 1.2, true);
  font-style: italic;
}

blockquote > p {
  padding: 0;
}
// ======================================================================
// Misc
// ======================================================================
a {
  color: $white;
}

a:not(.btn) {
  color: $link;

  font-family: $oldstandard;
  text-decoration: none;

  &:hover {
    color: $link-hover;

    text-decoration: $link-hover-decoration;

    // p & {
    //   //  border-bottom: 1px solid $dark;
    // }
  }

  &:active,
  &:focus {
    outline: none;
    color: $link-hover;
  }

  // p & {
  //   // border-bottom: 1px solid #e3e3e3;
  //   font-weight: 700;
  // }
}

hr {
  border: 0;
  border-top: $hr-style;
  display: block;
  height: 1px;
  padding: 0;
  margin-bottom: rem(24px);
}

sub,
sup {
  position: relative;
  top: -0.4rem;
  vertical-align: baseline;
}

sub {
  top: 0.4rem;
}

b {
  line-height: 1;
}

small {
  line-height: 1;
}

strong {
  font-weight: $bold;
  color: $dark;
}

cite,
dfn,
em,
q {
  line-height: $line-height;
}
// code {
//   @include type-free($base-font-size, 0px, 24px, 24px, true);
// }
//
// kbd {
//   margin-bottom: 1px;
//   padding: 0;
//   position: relative;
//   top: -1px;
// }
//
// pre {
//   @include type-free($base-font-size, 0px, 24px, 24px, true);
//   background-color: $dark;
//   color: $bright;
//   font-weight: $bold;
//   @include rem(padding, 12px);
//   @include rem(border-radius, 4px);
//   font-family: $terminal;
//   white-space: pre;
//   word-break: break-all;
//   word-wrap: break-word;
//   white-space: pre-wrap;
//   white-space: pre\9;
// }
